.date-time_range_picker {
  line-height: 0;

  label {
    position: relative;
    top: 67px;
    left: 6px;
    display: block;
    background: #f2f5f9;
    z-index: 1;
    width: 250px;
    transform: translate(0, -2.75rem);
  }

  @supports (not (-ms-ime-align: auto)) {
    label {
      color: #999;
      transform: translate(0.25rem, -1.5rem);
      transition: all 0.2s ease-out;
      padding: 5px;
    }
  }

  .float_label {
    top: 40px;
    width: max-content;
  }

  .react-datetimerange-picker__calendar.react-datetimerange-picker__calendar--open {
    inset: 75px auto auto 0px !important;
    z-index: 6;
  }

  input.react-datetimerange-picker__inputGroup__input {
    border: 0px !important;
    pointer-events: none;
    background: transparent !important;
  }
  .react-calendar__tile.react-calendar__month-view__days__day[disabled=''] {
    opacity: 0.1;
  }
  .react-datetimerange-picker__inputGroup__hour,
  .react-datetimerange-picker__inputGroup__minute {
    pointer-events: all !important;
  }

  .react-datetimerange-picker__clock {
    inset: 75px auto auto 75px !important;
  }

  .react-datetimerange-picker {
    padding-inline: 0 !important;
    padding-top: 10px;
  }
}
.date-time_range_picker.disabled {
  opacity: 0.5;
  pointer-events: none;
}
