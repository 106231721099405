.form-grid {
    input,
    .MuiInputBase-multiline,
    .MuiInputBase-formControl {
      background-color: white;
      border-radius: 12px;
    }
  }
  .app-suspense-loader{
    .suspense-loader{
        margin-top: 50vh;
    }
  }