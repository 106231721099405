.search-results {
    .search-results-wrapper {
        display: block;

        &.is-loading {
            display: none;
        }

        .search-results-error-message {
            margin-top: 20px;
            margin-bottom: 20px;
            padding-left: 52px;
        }
    }
}